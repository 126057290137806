import React from 'react';
import Layout from '../components/layout';

var NotFoundPage = function NotFoundPage() {
  return React.createElement(Layout, null, React.createElement("div", {
    id: "main",
    className: "alt"
  }, React.createElement("section", {
    id: "one"
  }, React.createElement("div", {
    className: "inner"
  }, React.createElement("h1", null, "NOT FOUND"), React.createElement("p", null, "You just hit a route that doesn't exist... the sadness.")))));
};

export default NotFoundPage;